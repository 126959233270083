body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a.ant-btn{
  line-height: 40px !important;
}
body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner4 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  background: #4b5564;
  overflow: hidden;
}
.banner4 .banner4-page {
  padding: 1px 0 0;
}
.banner4-title-wrapper {
  margin-top: 64px;
}
.banner4-title {
  color: #fff;
  font-size: 48px;
  line-height: 1.5;
}
.banner4-content {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin: 8px auto 16px;
}
.banner4-image,
.banner4-image-mobile {
  width: 80%;
  max-height: 60%;
  overflow: hidden;
  margin: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media screen and (max-width: 767px) {
  .banner4 {
    height: 500px;
  }
  .banner4 .banner4-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner4-title-wrapper {
    margin-top: 96px;
  }
  .banner4-title {
    font-size: 3em;
  }
  .banner4-image {
    width: 100%;
    padding: 0 24px;
  }
}
.feature7-wrapper {
  min-height: 564px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #f7f9fc;
}
.feature7-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature7-title-wrapper {
  text-align: center;
  margin-bottom: 40px;
}
.feature7-title-h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
}
.feature7-title-content {
  margin-top: 16px;
}
.feature7-block {
  margin-top: 28px;
}
.feature7-block-group {
  display: block;
  padding: 28px 24px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  background-image: url('https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*fMOFSpRXMxsAAAAAAAAAAABkARQnAQ');
  background-repeat: no-repeat;
  background-position: 100% 100%;
  transition: box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature7-block-group:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}
.feature7-block-image {
  float: left;
  width: 24px;
}
.feature7-block-title {
  font-size: 14px;
  float: left;
  margin-left: 8px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
}
.feature7-block-content {
  clear: both;
  color: rgba(0, 0, 0, 0.75);
}
@media screen and (max-width: 767px) {
  .feature7-wrapper {
    min-height: 1540px;
  }
  .feature7-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px;
  }
}
.content5-wrapper {
  background-color: #fafafa;
  min-height: 720px;
}
.content5-wrapper .content5 > p {
  text-align: center;
}
.content5-wrapper .content5-img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content5-wrapper .content5-img-wrapper .block {
  margin-bottom: 24px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content {
  display: block;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: none;
  color: rgba(0, 0, 0, 0.85);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
  width: 100%;
  height: 160px;
  display: block;
  background: #e9e9e9;
  padding: 5%;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content p {
  width: 100%;
  line-height: 30px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover p {
  bottom: 0;
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    height: 2000px;
    overflow: hidden;
  }
  .content5-wrapper .content5 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content5-wrapper .content5 ul li span {
    height: 168px;
  }
  .content5-wrapper .content5 ul li p {
    position: relative;
    bottom: 0;
  }
}
.content8-wrapper.home-page-wrapper {
  overflow: initial;
  width: calc(100% - 112px);
  min-height: 878px;
  margin: auto;
  border-radius: 4px;
  box-shadow: 0 32px 32px rgba(34, 94, 222, 0.08);
  background: #fff;
}
.content8-wrapper.home-page-wrapper .home-page {
  margin-top: -220px;
  padding: 64px 24px;
  overflow: hidden;
}
.content8-wrapper .content-wrapper {
  margin-top: 72px;
}
.content8-wrapper .content-wrapper .content8-block-wrapper {
  margin-bottom: 60px;
}
.content8-wrapper .content-wrapper .content8-block {
  width: 100%;
  max-width: 192px;
  margin: auto;
}
.content8-wrapper .content-wrapper .content8-img {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
.content8-wrapper .content-wrapper .content8-img img {
  width: 100%;
  display: block;
}
.content8-wrapper .content-wrapper .content8-img img[src=""] {
  background: linear-gradient(to top right, #d6defc, #fff);
  padding-bottom: 100%;
}
.content8-wrapper .content-wrapper .content8-title {
  font-size: 20px;
  color: #0d1a26;
  font-weight: 400;
  margin: 24px auto 8px;
  text-align: center;
  white-space: nowrap;
}
.content8-wrapper .content-wrapper .content8-content {
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  color: #697b8c;
}
@media screen and (max-width: 767px) {
  .content8-wrapper.home-page-wrapper {
    padding-bottom: 0;
    box-shadow: none;
    width: 100%;
  }
  .content8-wrapper.home-page-wrapper .content8.home-page {
    margin: auto;
    padding-bottom: 0;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .content8-block {
    max-width: 240px;
  }
}
.pricing0-wrapper .pricing0 {
  min-height: 370px;
  padding: 0 24px;
  display: flex;
  align-items: flex-end;
}
.pricing0-wrapper .pricing0-img-wrapper {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
}
.pricing0-wrapper .pricing0-img-wrapper .pricing0-img {
  display: block;
  width: 100%;
  max-width: 560px;
}
.pricing0-wrapper .pricing0-img-wrapper .pricing0-img img {
  display: block;
}
.pricing0-wrapper .pricing0-text-wrapper {
  min-height: 320px;
  padding: 0 40px;
  max-width: 560px;
  margin-bottom: 32px;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-content,
.pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
  position: relative !important;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
  font-size: 24px;
  font-weight: normal;
  color: #404040;
  margin: 72px auto 16px;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-content {
  font-size: 12px;
  color: #666;
  line-height: 1.5;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-pricing {
  font-size: 36px;
  color: #404040;
  margin: 32px 0 24px;
}
@media screen and (max-width: 767px) {
  .pricing0-wrapper {
    min-height: 720px;
  }
  .pricing0-wrapper .pricing0 {
    display: block;
  }
  .pricing0-wrapper .pricing0-img-wrapper {
    padding: 0;
    text-align: center;
    margin-top: 24px;
  }
  .pricing0-wrapper .pricing0-img-wrapper .pricing0-img {
    display: inline-block;
    width: 80%;
    margin: auto;
  }
  .pricing0-wrapper .pricing0-text-wrapper {
    height: auto;
    text-align: center;
    padding: 0;
    max-width: 100%;
  }
  .pricing0-wrapper .pricing0-text-wrapper .pricing0-content,
  .pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
    width: 100%;
    top: auto;
  }
  .pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
#Content5_0 .ant-col > .content5-block-content > .m5w1sjj11g7-editor_css {
  background-color: #ffffff;
}
#Content5_0 .ant-col > .content5-block-content > .m5vx8x7o1n6-editor_css {
  transition: none;
}
#Content5_0 .ant-col > .content5-block-content > .m5w3gykbd4-editor_css {
  background-color: #ffffff;
}
#Content5_0 .ant-col > .content5-block-content > .m5w3ocmyvze-editor_css {
  background-color: #ffffff;
}
#Content5_0 .ant-col > .content5-block-content > .m5w3wc1p06-editor_css {
  background-color: #ffffff;
}
#Content5_0 .ant-col > .content5-block-content > .m5w3xg898ge-editor_css {
  background-color: #ffffff;
}
#Content5_0 .ant-col > .content5-block-content > .m5w3hnpme3-editor_css {
  background-color: #ffffff;
}
#Content5_0 .ant-col > .content5-block-content > .m5w3y2s8uj7-editor_css {
  background-color: #ffffff;
}
#Banner4_0 .home-page > .banner4-title-wrapper > .m5vviartbga-editor_css {
  line-height: 25px;
  color: #404040;
}
#Banner4_0 .home-page > .banner4-title-wrapper > .m7wzx32ncfq-editor_css {
  font-weight: 300;
}
#Content5_0 .ant-row > .ant-col > .m7x09lc7yc7-editor_css {
  cursor: null;
}
#Feature7_0 .ant-col > .feature7-block-group > .m7x0q8816h7-editor_css {
  margin: 2px 0 16px 8px;
}
#Feature7_0 .ant-col > .feature7-block-group > .m7x0qv2t1au-editor_css {
  margin: 2px 0 16px 8px;
}
#Feature7_0 .ant-row > .ant-col > .m7x0quhx77g-editor_css {
  margin: 0 0;
}
#Feature7_0 .ant-col > .feature7-block-group > .m7x0rpd7mc-editor_css {
  margin: 0px 0px 66px 50px;
  padding: 0px 0px;
}
#Feature7_0 .ant-col > .feature7-block-group > .m7x1a6t7s2m-editor_css {
  margin: 0 0 70px;
  padding: 0px 0px;
}
#Feature7_0 .ant-col > .feature7-block-group > .m7x1kdear-editor_css {
  margin: 2px 0 16px 8px;
}
#Feature7_0 .ant-col > .feature7-block-group > .m7x19mjnss-editor_css {
  margin: 2px 0 16px 8px;
}
#Feature7_0 .home-page > .m7x16zmtfs-editor_css > .m7x176so95e-editor_css {
  clear: right;
  margin: 0 0 0 0px;
  padding: 0 0;
}
#Feature7_0 .home-page > .m7x0m3skv2t-editor_css {
  clear: right;
  margin: -20px 0px 40px;
}
#Feature7_0 .m7x1739zrgg-editor_css {
  bottom: -30px;
  padding: 64px 24px 0px;
}
#Banner4_0 .banner4-title-wrapper > .m7x3jub51-editor_css > .m7x3gzo322d-editor_css {
  width: 100px;
  padding: 4px 15px;
}
#Banner4_0 .banner4-title-wrapper > div > .m7x3gzo322d-editor_css {
  color: #fbfbfb;
  height: 40px;
  background-color: #00a2ae;
  border-width: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 4px 15px;
}
#Teams4_0 .home-page > .title-wrapper > .m7x40izaw1m-editor_css {
  margin: 20px 0px 16px;
}
#Pricing0_0 .ant-col > div > .m7x4eympi5e-editor_css {
  height: 40px;
  background-color: #00a2ae;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 4px 15px;
}
#Pricing0_0 .ant-row > .ant-col > .m7x4ld8pkpg-editor_css {
  font-size: 14px;
}
#Pricing0_0 .ant-row > .ant-col > .m7x52gkmi6n-editor_css {
  font-size: 30px;
  margin: 32px 0px 15px;
}
#Content5_0 .ant-row > .ant-col > .m5w3avojchb-editor_css {
  cursor: null;
}
#Content5_0 .ant-col > .content5-block-content > .m5w3c2ggy7g-editor_css {
  cursor: null;
  background-color: #ffffff;
}
#Teams4_0 .home-page > .m7x46b7facf-editor_css {
  margin: 0px auto 64px;
}
#Teams4_0 .m7x422juuju-editor_css {
  margin: 0px auto auto;
}
#Teams4_0.m7x3tm7tnsu-editor_css {
  display: flex;
  text-align: center;
  padding: 0 0 0px;
}
@media screen and (max-width: 767px) {
  #Pricing0_0 .ant-row > .m7x68brs7e-editor_css {
    margin: 0 0 bottom;
  }
}
#Pricing0_0.m7x5rb32xsl-editor_css {
  margin: 20px 0 0;
}
@media screen and (max-width: 767px) {
  #Pricing0_0.m7x5rb32xsl-editor_css {
    min-height: 500px;
    margin: 20px 0px 0px;
    padding: 0px 0px;
  }
}
#Banner4_0 .home-page > .banner4-title-wrapper > .m5wbrbus3ji-editor_css {
  font-size: 18px;
  color: #00a854;
  position: relative;
  top: -25px;
  margin: 0px 0px;
}
#Banner4_0 .home-page > .banner4-title-wrapper > .m7y4ndutwka-editor_css {
  transition: none;
}
#Banner4_0 .home-page > .m5vsu6x0exl-editor_css {
  position: relative;
  top: 50px;
  margin: auto auto 90px;
}
#Banner4_0 .m5wa56oyjy-editor_css {
  background-color: rgba(214, 206, 206, 0);
  padding: 0px 0px 2px;
}
#Banner4_0.m5wa5ho9pqr-editor_css {
  height: 100%;
  background-color: #eef1e8;
  padding: 0px 0px 10px;
}
#Content5_0 .home-page > .m7x0m79t0pb-editor_css {
  margin: 0px auto 64px;
}
#Content5_0 .m7x0idl5whr-editor_css {
  top: -20px;
  padding: 0px 24px;
}
#Feature7_0.m7x16t9a1qn-editor_css {
  height: 100%;
  min-height: 450px;
  bottom: 0px;
  float: right;
  padding: 0px 0px;
}
#Content5_0.m5vvi2v5sdd-editor_css {
  background-clip: padding-box;
  height: 100%;
  min-height: 650px;
  top: 0px;
  bottom: -20px;
  margin: 0px 0px;
  padding: 50px 0px 0px;
}
#Feature7_0 .home-page > div > .m7x176so95e-editor_css {
  margin: 0px -10px 10px;
  padding: 0px 0px 40px;
}
#Banner4_0 .home-page > .banner4-title-wrapper > .m82ql39vdaf-editor_css {
  width: 77px;
  height: 80px;
  margin: 0px auto;
}
@media screen and (max-width: 767px) {
  #Banner4_0 .home-page > .banner4-title-wrapper > .m82ql39vdaf-editor_css {
    width: 77px;
    height: 80px;
  }
}
#Banner4_0 .home-page > .banner4-title-wrapper > .m5uw5qt5q8-editor_css {
  font-family: Verdana;
  color: #00a854;
  clear: right;
  margin: 0px auto 0.5em;
}
#Banner4_0 .banner4-title-wrapper > .m7y4ndutwka-editor_css > .m7x3gzo322d-editor_css {
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  #Banner4_0 .home-page > .m82qo1qld2-editor_css {
    margin: 96px 0 0px 0px;
  }
}

